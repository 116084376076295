<template>
<!--<h4>{{materias[0].nombre_carrera}}</h4>-->

<h4> Carrera: {{tit}}</h4>

 <div class="info">
  
<table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Código Materia</th>
           <th scope="col">Nivel</th>
          <th scope="col">Descripción</th>
          <th scope="col">Acciones</th>
        
        </tr>
      </thead>
      <tbody>
       <tr v-for="(m, index) in materias" :key="index">
        <td>{{index+1}}</td>
        <td>{{ m.cod_materia }}</td>
        <td>{{m.id_nivel}}</td>
        <td>{{ m.nombre }}</td>
        
        <td>

            <button type="button" class="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom" v-on:click="openPdf(m.archivo)" >
              <i class="far fa-eye"></i> 
            </button>
          <!--
            <button type="button" class="btn btn-success"><i class="fas fa-download"></i></button>
            <button type="button" class="btn btn-danger" data-id ="{{m.cod_materia}}" data-toggle="modal" data-target="#uploadModal" ><i class="fas fa-upload"></i></button>    
              -->       
        </td>
        <!--<td><button v-on:click="openPdf(m.archivo)" type="button" class="btn btn-info btn-sm">Materias</button></td>
        <td><a href="http://localhost/unisis/img/ADM511.pdf" target="_blank">link</a></td>
        -->
      </tr>
        
      </tbody>
    </table>


    <!-- Modal -->
    <div id="uploadModal" class="modal fade" role="dialog">
      <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        
        <div class="modal-header">
          <h4 class="modal-title">Subir archivo pdf</h4>
            <h1>ggg</h1>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          
        </div>
        <div class="modal-body">
          <!-- Form -->
          <form method='post' action='' enctype="multipart/form-data">
            Seleccionar archivo : <input type='file' name='file' id='file' class='form-control' ><br>
            <input type='button' class='btn btn-info' value='Upload' id='btn_upload'>
          </form>

          <!-- Preview-->
          <div id='preview'></div>
        </div>
  
      </div>

      </div>
    </div>

    </div>

    
</template>

<script>
import axios from "axios";

export default {

data(){
        return{
            selected:'',
            materias:[],
            tit:'',
            
            titulo:"UNICEN-Programas Analiticos -Materias",
           
            error:[]
        }
    },
    
     async mounted () {
        //const page = 1;
        //const limit = 2;
        try {
           
            //const resm = await axios.get('http://190.104.11.190:3000/programa/80');
           
            const resm = await axios.get('http://190.104.11.190:3000/programa/'+this.$route.params.data+'');
           
            this.materias = resm.data;
            this.tit = this.materias[0].nombre_carrera;
            console.log(this.materias);

           

           
            

            //this.pages = res.data;
        } catch (error) {
            this.error.push(error);
        }
        
    },  
    methods:{
      openPdf: function (arch) {  
           // window.open('http://localhost/unisis/img/'+arch+'', "_blank");    
           window.open('http://unicenvirtual.com/appunisis/public/'+arch+'', "_blank");
      }
    }
}
</script>

<style>
.table-bordered{
font-size: 14px;
}

btn-info {
font-size: 10px;
}
</style>