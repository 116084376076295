<template>
  <h3> {{titulo}}</h3>

    <!--

    <ag-grid-vue style="width: 500px; height: 500px;"
                 class="ag-theme-alpine"
                 :columnDefs="columnDefs"
                 :rowData="rowData">
    </ag-grid-vue>
-
    <label for="carr">Seleccione una Carrera:</label>

    <select name="selected" v-model="selected" @change="onChange(this.value)" >
       <option v-for="carr in lista_carreras" v-bind:value="{ id: carr.id_carrera, text: carr.nombre }">{{ carr.nombre }}</option>
   </select>
   <h1>Value:
   {{selected.id}}
   </h1>

    <button v-on:click="recuperar">Mostrar Materias</button>
    -->
<!--
    <ol>
       <li v-for="mat in materias" :key="mat.cod_materia">{{mat.nombre}}</li>
    </ol>-->

    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Código Carrera</th>
          <th scope="col">Carrera</th>
          <th scope="col">Enlace</th>
        </tr>
      </thead>
      <tbody>
       <tr v-for="(c, index) in lista_carreras" :key="index">
        <td>{{ c.id_carrera }}</td>
        <td>{{ c.cod_carrera }}</td>
        <td>{{ c.nombre }}</td>
        <td><button v-on:click="ir_materias(c.id_carrera)" type="button" class="btn btn-info btn-sm">Materias</button></td>
      </tr>
        
      </tbody>
    </table>


  </template>
<script>
import  axios from "axios";
//import mat  from "../views/materias.vue";
//import Vue from 'vue'
//import VueRouter from 'vue-router'

//Vue.use(VueRouter)


export  default {
      data(){
        return{
            selected:'',
            lista_carreras:[],
            materias:[],
            
            titulo:"Programas Analíticos - UNICEN",
            numero:14,
            nombres:["Juan","Jose"],
            error:[]
        }
    },
    
     async mounted () {
        //const page = 1;
        //const limit = 2;
        try {
            //const res = await axios.get('http://localhost:3000/projects')
            const res = await axios.get('http://190.104.11.190:3000/carreras')
            this.lista_carreras = res.data;
            console.log(this.lista_carreras);

            //const resm = await axios.get('http://190.104.11.190:3000/programa/80')
            //this.materias = resm.data;
            //console.log(this.materias);

            //this.pages = res.data;
        } catch (error) {
            this.errors.push(error);
        }
        
    },

    methods:{
      ir_materias:function(cod){
            //alert(cod);
            this.$router.push({
              name:'materias',
              params:{data:cod}
            }) ;     
        },
       recuperar:function (param){

         try {
          
            this.materias = resmat.data                               ;
            console.log(this.materias);
           
          } catch (error) {
              this.errors.push(error);
          }
      },

       onChange:function(event){
            var par =this.selected.id;
            alert('ee'+par);
          
           this.recuperar(par);
            
        }

    }

}

</script>

<style>
.table-bordered{
font-size: 14px;
}

btn-info {
font-size: 10px;
}


</style>